//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

// 조직도
.orgchart-container {
	border: 0 !important;
	.orgchart {
		&.departTree {
			background-image: unset !important;
			height: inherit;
		}		
		ul {
			justify-content: center;
		}
		.border-danger {
			border-color: $info !important;
		}

		li::before,
		li::after {			
			border-top-color: $info !important;
			border-top-width: 1px;
		}
		
		//li:only-child::before,
		//li:only-child::after,
		.oc-node:not(:only-child)::after,
		li:only-child::before,
		.oc-node::before,
		.oc-node::after {
			width: 1px;
			height: 15px;
			//top: -12px;
			//left: -1px;
			background-color: $info !important;
		}
		li::before {
			left: -1px;
		}
		.oc-node::before {
			top: -12px;
		}
	}
}

// 트리
.rstcustom__collapseButton::after, 
.rstcustom__expandButton::after {
	border-top-color: $dark-border-color !important;
	border-width: 5px !important;
	border-left-width: 5px !important;
	border-right-width: 5px !important;
}

// Calander
.rbc-calendar {
	.rbc-month-view {
		border-color: $table-border-color;
		.rbc-month-header {
			.rbc-header {
				border-bottom: 1px #e7e7e7 solid;
			}
		}
	}
	.rbc-header {
		font-size: inherit;
	}
}

// 캘린더
.rbc-event {
	background-color: unset;
	font-weight: inherit;
	color: initial;
	padding: 0.2rem 0.5rem;
}
//멀티그리드 체크박스 column
.ReactVirtualized__Grid__innerScrollContainer {
	.form-check{
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	}
}
//대시보드 높이 지정
.dashboard-height {
	height : 40vh;
}
.dashboard-hover-effect:hover {
	background-color: rgb(34, 139, 34);
}

.block-wrapper {
	position: relative;
	background: white;
	padding: 20px;
	margin-bottom: 10px;
	border: 1px solid lightgrey;
	border-radius: 4px;
	cursor: move;
	&:hover{
		background-color: rgba($color: red, $alpha: 0.5);
	}
}